var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-wrapper auth-v1 Crad-height hide-visible"},[_c('div',{staticClass:"auth-inner py-2 Card-width-1"},[_c('b-card',{},[_c('div',{staticClass:"d-flex justify-content-end"},[_c('feather-icon',{staticClass:"mr-2 cursor-pointer",attrs:{"id":"XIcon","icon":"XIcon","size":"24"},on:{"click":function($event){return _vm.$router.go(-1)}}}),_c('b-tooltip',{attrs:{"target":"XIcon","triggers":"hover"}},[_vm._v(" "+_vm._s(_vm.$t('GenerateTxCostReport.Close'))+" ")])],1),_c('b-card-title',{staticClass:"frogot-password-card-title font-weight-bold mb-3 d-flex align-items-center justify-content-center",attrs:{"title-tag":"h2"}},[_vm._v(" "+_vm._s(_vm.$t('GenerateTxCostReport.GenerateReport'))+" ")]),_c('validation-observer',{ref:"loginForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{staticClass:"auth-login-form mt-2",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.generateStatementReport)}}},[_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"name":"network currency","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('h5',[_vm._v(_vm._s(_vm.$t('GenerateTxCostReport.NetworkCurrency')))]),_c('b-form-group',[_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"title","options":_vm.option,"placeholder":'ETH'},model:{value:(_vm.coinType),callback:function ($$v) {_vm.coinType=$$v},expression:"coinType"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"name":"address","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('h5',[_vm._v(_vm._s(_vm.$t('GenerateTxCostReport.Address')))]),_c('b-form-group',[_c('div',{staticClass:"d-flex justify-content-between align-items-center w-100"},[_c('Search',{staticStyle:{"float":"left"},attrs:{"addresses":_vm.addresses,"set-value":_vm.setValue},model:{value:(_vm.address),callback:function ($$v) {_vm.address=$$v},expression:"address"}}),_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.v-primary",modifiers:{"hover":true,"v-primary":true}}],class:[
                      'cursor-pointer',
                      'w-10',
                      { 'fill-current': _vm.isFavourite },
                      _vm.isFavourite ? 'primary-color' : 'text-muted' ],attrs:{"icon":"StarIcon","title":_vm.$t('GenerateTxCostReport.AddToFavorites'),"size":"20"},on:{"click":_vm.addUserFavouriteAddress}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])])]}}],null,true)})],1),_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"name":"report currency","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('h5',[_vm._v(_vm._s(_vm.$t('GenerateTxCostReport.ReportCurrency')))]),_c('b-form-group',[_c('v-select',{attrs:{"placeholder":"USD","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"title","options":_vm.reportCurrencyOption},model:{value:(_vm.reportCurrency),callback:function ($$v) {_vm.reportCurrency=$$v},expression:"reportCurrency"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"name":"start time","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('b-form-group',[_c('h5',[_vm._v(_vm._s(_vm.$t('GenerateTxCostReport.StartTime')))]),_c('vue-ctk-date-time-picker',{attrs:{"label":_vm.$t('GenerateTxCostReport.StartTimePlaceholder'),"format":'YYYY-MM-DD HH:mm',"formatted":'DD/MM/YYYY HH:mm',"no-label":"","color":"#7367f0","button-color":"#7367f0","max-date":new Date().toString(),"position":"top"},model:{value:(_vm.startTime),callback:function ($$v) {_vm.startTime=$$v},expression:"startTime"}}),_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(errors[0] || _vm.startDateErr)+" ")])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"name":"end time","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('b-form-group',[_c('h5',[_vm._v(_vm._s(_vm.$t('GenerateTxCostReport.EndTime')))]),_c('vue-ctk-date-time-picker',{attrs:{"label":_vm.$t('GenerateTxCostReport.EndTimePlaceholder'),"format":'YYYY-MM-DD HH:mm',"formatted":'DD/MM/YYYY HH:mm',"no-label":"","color":"#7367f0","button-color":"#7367f0","max-date":new Date().toString(),"position":"top"},model:{value:(_vm.endTime),callback:function ($$v) {_vm.endTime=$$v},expression:"endTime"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),(_vm.requiredPoints)?_c('b-col',{attrs:{"md":"12"}},[_c('h5',[_vm._v(_vm._s((_vm.requiredPoints + " request points required")))])]):_vm._e(),_c('b-col',{attrs:{"md":"12"}},[_c('b-button',{attrs:{"type":"submit","variant":"primary","block":""}},[_vm._v(" "+_vm._s(_vm.$t('GenerateTxCostReport.GenerateNewReports'))+" ")])],1)],1)]}}])})],1)],1),_c('Loader',{attrs:{"show":_vm.isLoading}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }